import { Button, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
const Shaxmatka2Change = ({value, setValue}) => {
    const { t, i18n } = useTranslation()
	const [open, setOpen] = useState(false)
	let anchorEl = document.getElementById("language-btn")
    const menuToggle = () => {
		setOpen((prev) => !prev)
	}
  return (
        <motion.div viewport={{ once: true, amount: 0.25 }} className='language-wrapper ml-2'>
			<Button id="language-btn" onClick={menuToggle} variant="language">
                <i className='bi bi-three-dots-vertical'></i>
			</Button>
			<Menu
				open={open}
				anchorEl={anchorEl}
				disableScrollLock={true}
				onClose={menuToggle}
				className="language-menu"
			>
				<MenuItem
					onClick={()=> {
                        setValue("number")
                        menuToggle()
                    }}
					className={`language-menu-item${value === "number" ? " active-link" : ""}`}
				>
					{t("common.fields.homeNumber")}
				</MenuItem>
				<MenuItem
					onClick={()=> {
                        setValue("room")
                        menuToggle()
                    }}
					className={`language-menu-item${
						value === "room" ? " active-link" : ""
					}`}
				>
					{t("common.fields.rooms")}
				</MenuItem>
				
			</Menu>
		</motion.div>
  )
}

export default Shaxmatka2Change