import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { LinearProgress, Pagination } from "@mui/material"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useQuery } from "react-query"
import BaseTableHead from "./parts/BaseTableHead"
import setTableCellType from "utils/setTableCellType"
import useColumnToggle from "hooks/useColumnToggle"
import TableColumnToggleDrawer from "components/TableColumnToggleDrawer"
import addActiveInTableCell from "utils/addActiveInTableCell"
import { useSearchParams } from "react-router-dom"
import GenerateCheque from "../action-buttons/GenerateCheque"
import { useTranslation } from "react-i18next"
import { Fragment, useEffect, useState } from "react"
import { paymentTypeVariants } from "shared/tableColVariantsList"
import ContractViewActionButton from "../action-buttons/ContractViewActionButton"
import useNavigationByRole from "hooks/useNavigationByRole"
import moment from "moment"

export default function PaymentTable({
	emitTableColumns = {},
	emitRefetch = {},
	actionGetCheque = null,
	actionContractView = null,
	setPaymentsDetails = () => {},
}) {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const { linkFn } = useNavigationByRole()
	const headCells = [
		{ code: "payment_number", label: t("common.table.paymentId") },
		{ code: "contract", label: t("common.table.contractNumber") },
		{ code: "custom", label: t("common.table.custom") },
		{ code: "date", label: t("common.table.date") },
		{ code: "sum", label: t("common.table.paymentAmount") },
		{ code: "type_id", label: t("common.table.typeId") }
	]
	const columns = [
		{ code: "payment_number" },
		{ code: "contract", type: "nested", childStr: "name" },
		{
			code: "contract",
			type: "nestedFieldsChain",
			childStr: "custom",
			childStrings: ["surname", "name", "middlename"]
		},
		{ code: "date", type: "date" },
		{ code: "sum", type: "priceCurrency" },
		{
			code: "type_id",
			type: "customStatus",
			variants: paymentTypeVariants
		}
	]

	const { tableData, tableHeadCells, tableColumnCells, setTableHeadCells } =
		useColumnToggle(
			"payments",
			addActiveInTableCell(headCells),
			addActiveInTableCell(columns)
		)
	const [queryPath, setQueryPath] = useState("")
	const [order, setOrder] = useState({
		paymentNumber: false,
		date: false
	});
    const [orderBy, setOrderBy] = useState();
	const [hasError, setHasError] = useState(false)
	const {
		error,
		data: rows,
		isLoading,
		isFetching,
		isError,
		refetch
	} = useQuery({
		queryKey: "paymentsTableKey",
		queryFn: async function () {
			const response = await axiosPrivate.get(queryPath)
			setPaymentsDetails(response?.data?.data)
			return response?.data?.data && response?.data?.data.hasOwnProperty("0")
				? response?.data?.data[0]
				: null
		},
		onSettled: () => {
			emitRefetch.setRefetch(false)
		},
		enabled: false,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})
	const [searchParams, setSearchParams] = useSearchParams()
	const [page, setPage] = useState(
		(searchParams.get("page") && parseInt(searchParams.get("page"))) || 1
	)
	console.log(order);
	
	useEffect(() => {
		if (queryPath && queryPath.length > 0) {
			refetch()
			emitRefetch.setRefetch(true)
		}
	}, [queryPath])
	const sortedData = rows?.data?.sort((a, b) => {
        if (order.date) {
            return a[orderBy] < b[orderBy] ? -1 : 1;
        } 
		else if(order.paymentNumber){
            return a[orderBy] < b[orderBy] ? -1 : 1;
		}
		else if(!order.paymentNumber){
            return a[orderBy] > b[orderBy] ? -1 : 1;
		}
		else {
            return a[orderBy] > b[orderBy] ? -1 : 1;
        }
    });

	useEffect(() => {
		if (emitRefetch && emitRefetch.refetch) {
			refetch()
		}
	}, [emitRefetch.refetch])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
		searchParams.set("page", newPage)
		setSearchParams(searchParams, { replace: true })
	}

	const createQueryPath = () => {
		let localSearchParams = {
			...Object.fromEntries([...searchParams])
		}
		let newQueryPath = "/dictionary/payments"
		Object.keys(localSearchParams).forEach((item, index) => {
			if (index === 0) {
				newQueryPath += `?${item}=${localSearchParams[item]}`
			} else {
				newQueryPath += `&${item}=${localSearchParams[item]}`
			}
			setQueryPath(localSearchParams&&newQueryPath)
		})
		if (!isNaN(localSearchParams.page)) {
			setPage(parseInt(localSearchParams.page))
		}
	}
	useEffect(() => {
		createQueryPath()
	}, [searchParams])
	return (
		<Box className="base-table w-full h-full flex flex-col">
			<TableContainer className="flex-auto h-[calc(100vh-244px)]">
				<Table
					stickyHeader
					sx={{ minWidth: 750, height: "max-content" }}
					aria-labelledby="tableTitle"
				>
					{tableHeadCells && tableColumnCells && (
						<Fragment>
							<BaseTableHead
							headCells={tableHeadCells} 
							filterSort 
							sortByPayment
							ordered={order}
							setOrdered={setOrder}
							setOrderBy={setOrderBy}
							orderBy={orderBy}
							/>
							{isLoading || isFetching ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={tableHeadCells.length + 1}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								</TableBody>
							) : isError ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={tableHeadCells.length + 1}>
											<div className="flex flex-col items-center">
												{error?.response?.data?.message && (
													<span className="text-red-600 font-medium">
														{error?.response?.data?.message}
													</span>
												)}
												{error?.response?.data?.details &&
													error?.response?.data?.details[0]?.message && (
														<div>
															<span className="text-red-600 font-medium">
																{t("common.errors.message")}
															</span>
															<span>
																{error?.response?.data?.details[0]?.message}
															</span>
														</div>
													)}
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							) : rows && rows.data  && rows.data.length > 0 ? (
								<Fragment>
									<TableBody className="overflow-hidden">
										{sortedData.map((row, rowIndex) => {
											return (
												<TableRow hover tabIndex={-1} key={"row-" + rowIndex}>
													{tableColumnCells.map((column, columnIndex) => (
														<TableCell
															align={
																tableHeadCells[columnIndex]?.numeric
																	? "right"
																	: "left"
															}
															key={"column-" + columnIndex}
														>
															{column.type
																? setTableCellType(
																		column,
																		row[column.code],
																		row
																  )
																: row[column.code]}
														</TableCell>
													))}
													<TableCell align="right">
														{actionContractView && (
															<ContractViewActionButton
																link={linkFn(
																	`/BASE/contract/view/${
																		row?.contract_id ? `${row.contract_id}` : ""
																	}`
																)}
															/>
														)}
														{actionGetCheque && (
															<GenerateCheque id={row.id} sum={row.sum} />
														)}
													</TableCell>
												</TableRow>
											)
										})}
									</TableBody>
								</Fragment>
							) : (
								<TableBody>
									<TableRow>
										<TableCell colSpan={tableHeadCells.length + 1}>
											<span className="no-data-found-wrapper">
												<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
												{t("common.global.noDataFound")}
											</span>
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Fragment>
					)}
				</Table>
			</TableContainer>
			{rows && rows.data && rows.data.length > 0 && (
				<div className="p-3 mb-2 mt-4 flex items-center justify-center rounded-lg my-shadow-2">
					<Pagination
						count={Math.ceil(rows.total / rows.per_page) || 1}
						page={page}
						onChange={handleChangePage}
						variant="outlined"
						color="primary"
						showFirstButton
						showLastButton
					/>
				</div>
			)}

			{emitTableColumns && Object.keys(emitTableColumns).length === 2 && (
				<TableColumnToggleDrawer
					open={emitTableColumns.open}
					setOpen={emitTableColumns.setOpen}
					tableData={tableData}
					setTableHeadCells={setTableHeadCells}
				/>
			)}
		</Box>
	)
}
