import {
	Box,
	Button,
	ButtonBase,
	CircularProgress,
	Fab,
	Grid,
	LinearProgress,
	Switch,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs
} from "@mui/material"
import useFormSubmit from "hooks/useFormSubmit"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useNotification from "hooks/useNotification"
// import OriginatorAddEditModal from "./message-parts/OriginatorAddEditModal"
import { NavLink, Outlet, useLocation, useMatch  } from "react-router-dom"

const Messages = () => {
	const { t, i18n } = useTranslation()
	const [itemId, setItemId] = useState("")
	const [openTemplateModal, setOpenTemplateModal] = useState(false)
	const { setComponent } = useTopPanel()
	const location = useLocation()
	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("settings.message.title")}
			</div>
		)
	}, [i18n.language,location])
	return (
		<div className="component-add-edit-wrapper mx-4">
			<div className="component-add-edit-body mt-3 pb-4 flex gap-4">
				<div className="w-[20%]">
					<div className="listMessages gap-1 flex rounded-[7px] p-2 bg-[#fff]"  style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585", flexDirection:"column"}}>
						<NavLink
							className={({ isActive }) => isActive ? "navigation-link-top no-underline active" : "navigation-link-top no-underline"}
							to={"contract-shablon-settings"}
						>
						<Button fullWidth className="settings-item-btn">
							<span className="relative">
								<i className="bi bi-airplane-engines-fill text-[10px] rotate-[60deg] absolute bottom-0 -ml-1  hover:[bg-white] z-10 flex" />
								<i className="bi bi-envelope" />
							</span>{" "}
							<span className="ml-2">
								{t("settings.message.navLinks.messageSending")}
							</span>
						</Button>
						</NavLink>
						{/* <NavLink
							className={({ isActive }) => isActive ? "navigation-link-top no-underline active" : "navigation-link-top no-underline"}
							to={"messageShablon"}
							>
								<span>
								<span className="relative">
								<i className="bi bi-file-earmark-text-fill text-[10px] absolute bottom-0 -ml-1  hover:[bg-white] z-10 flex" />
								<i className="bi bi-envelope" />
								</span>{" "}
									{t("settings.message.navLinks.messageTemplate")}
								</span>
						</NavLink>
						<NavLink
							className={({ isActive }) => isActive ? "navigation-link-top no-underline active" : "navigation-link-top no-underline"}
							to={"messageSetting"}
							>
								<span>
								<span className="relative">
								<i className="bi bi-gear-fill text-[10px] absolute bottom-0 -ml-1  hover:[bg-white] z-10 flex" />
								<i className="bi bi-envelope" />
								</span>{" "}
									{t("settings.message.navLinks.messageSetting")}
								</span>
						</NavLink> */}
					</div>
				</div>

				<div className="message-templates-wrapper w-[80%]">

				<Outlet />


{/* 					
					<TableContainer className="flex-auto h-full">
						<Table
							stickyHeader
							sx={{ minWidth: 750, height: "max-content" }}
							aria-labelledby="tableTitle"
						>
							<TableHead>
								<TableRow>
									<TableCell>№</TableCell>
									<TableCell>{t("common.table.messageTemplate")}</TableCell>
									<TableCell>{t("common.table.autoSend")}</TableCell>
									<TableCell>{t("common.table.actions")}</TableCell>
								</TableRow>
							</TableHead>
							{messageTemplateListQuery?.isLoading ||
							messageTemplateListQuery?.isFetching ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={4}>
											<LinearProgress />
										</TableCell>
									</TableRow>
								</TableBody>
							) : messageTemplateListQuery?.isError ? (
								<TableBody className="overflow-hidden">
									<TableRow>
										<TableCell colSpan={4}>
											<div className="flex flex-col items-center">
												{messageTemplateListQuery?.error?.response?.data
													?.message && (
													<span className="text-red-600 font-medium">
														{
															messageTemplateListQuery?.error?.response?.data
																?.message
														}
													</span>
												)}
												{messageTemplateListQuery?.error?.response?.data
													?.details &&
													messageTemplateListQuery?.error?.response?.data
														?.details[0]?.message && (
														<div>
															<span className="text-red-600 font-medium">
																{t("common.errors.message")}
															</span>
															<span>
																{
																	messageTemplateListQuery?.error?.response
																		?.data?.details[0]?.message
																}
															</span>
														</div>
													)}
											</div>
										</TableCell>
									</TableRow>
								</TableBody>
							) : messageTemplateListQuery?.data &&
							  messageTemplateListQuery?.data.length > 0 ? (
								<TableBody className="overflow-hidden">
									{messageTemplateListQuery?.data.map((row, rowIndex) => (
										<TableRow hover tabIndex={-1} key={"row-" + rowIndex}>
											<TableCell>{rowIndex + 1}</TableCell>
											<TableCell>{row?.name}</TableCell>
											<TableCell>
												<Switch
													color="secondary"
													checked={row?.completed}
													onChange={(event) =>
														handleChangeAutoMessageSend(
															event?.target?.checked,
															row?.id
														)
													}
												/>
											</TableCell>
											<TableCell>
												<Fab
													color="warning"
													variant="action"
													aria-label="edit-modal"
													onClick={() => {
														setOpenTemplateModal(true)
														setItemId(row?.id)
													}}
												>
													<i className="bi bi-pencil-square" />
												</Fab>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							) : (
								<TableBody>
									<TableRow>
										<TableCell colSpan={4}>
											<span className="no-data-found-wrapper">
												<i className="bi bi-exclamation-octagon icon-xl" />{" "}
												{t("common.global.noDataFound")}
											</span>
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer> */}
				</div>
			</div>

			{/* {openTemplateModal && (
				<OriginatorAddEditModal
					open={openTemplateModal}
					setOpen={setOpenTemplateModal}
					itemId={itemId}
					setItemId={setItemId}
				/>
			)} */}
		</div>
	)
}

export default Messages
