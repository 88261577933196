import { Button, CircularProgress, Grid } from "@mui/material"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect, useState } from "react"
import ExpenseTable from "components/ui/tables/ExpenseTable"
import { paymentTypeVariants } from "shared/tableColVariantsList"
import ExpenseAddModal from "./ExpenseAddModal"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useSearchParams } from "react-router-dom"
import moment from "moment"
import * as XLSX from "xlsx"
import { useTranslation } from "react-i18next"
import ExpenseFilterComponent from "components/ui/filters/ExpenseFilterComponent"
import { saveAs } from "file-saver"
import FromDateToDateFilter from "components/ui/filters/items/FromDateToDateFilter"
import ROLE_TYPE_LIST from "shared/roleTypeList"
import useAuth from "hooks/useAuth"
const Expense = () => {
	const [{ user }] = useAuth()
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("expense.title")}
			</div>
		)
	}, [i18n.language])
	const [refetch, setRefetch] = useState(false)
	const [openExpenseModal, setOpenExpenseModal] = useState(false)
	const [open, setOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(false)
	const [dataLoading2, setDataLoading2] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const axiosPrivate = useAxiosPrivate()
	const [expanded, setExpanded] = useState(false)

	// const handleGetDataIncome = () => {
	// 	setDataLoading2(true)
	// 	let entries = Object.fromEntries(searchParams)
	// 	let values = { from: entries?.from, till: entries?.till }
	// 	axiosPrivate
	// 		.post("/utilities/data/exportIncomeAndExpenses", JSON.stringify(values), {
	// 			headers: { "Content-Type": "application/json" }
	// 		})
	// 		// t(
	// 		// 	paymentTypeSelectOptions.find(
	// 		// 		(paymentType) => paymentType.code === item?.type_id
	// 		// 	)?.label
	// 		// )
	// 		.then((res) => {
	// 			console.log(res);
	// 			// /utilities/data/exportExpenses
	// 			if (res && res.data) {
	// 				let rows1 = res.data?.map((item) => ({
	// 					"To'lov raqami": item?.id,
	// 					"Shartnoma raqami": item?.object_name,
	// 					"Mijoz": item?.who_made,
	// 					Sana: moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
	// 					"Mas'ul": "",
	// 					"To'lov turi": item?.payment_type,
	// 					// t(
	// 					// 	paymentTypeSelectOptions.find(
	// 					// 		(paymentType) => paymentType.code === item?.type_id
	// 					// 	)?.label
	// 					// ),
	// 					"To'lov maqsadi": item?.comments,
	// 					Summa: item?.sum,
	// 					Tranzaksiya: "Kiruvchi"
	// 				}))
	// 				let rows = res.data?.map((item) => [
	// 					item?.id,
	// 					item?.object_name,
	// 					item?.who_made,
	// 					moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
	// 					// "",
	// 					item?.payment_type,
	// 					// t(
	// 					// 	paymentTypeSelectOptions.find(
	// 					// 		(paymentType) => paymentType.code === item?.type_id
	// 					// 	)?.label
	// 					// ),
	// 					item?.comments,
	// 					item?.sum,
	// 					// "",
	// 					"Chiquvchi"
	// 				])
	// 				rows.unshift([
	// 					"Chiqim raqami",
	// 					"Obyekt Nomi",
	// 					"Made qilgan odam",
	// 					"Sana",
	// 					"To'lov turi",
	// 					"To'lov maqsadi",
	// 					"Summa",
	// 					"Tranzaksiya"
	// 				])

	// 				let worksheet = XLSX.utils.aoa_to_sheet(rows)
	// 				// let worksheet = XLSX.utils.json_to_sheet(rows1)

	// 				// let maxWidthArr = Object.keys(rows[0]).map((item) => {
	// 				// 	return {
	// 				// 		wch: rows
	// 				// 			.map((r) => r[item])
	// 				// 			.reduce((acc, curr) => {
	// 				// 				return Math.max(acc, curr.toString().length)
	// 				// 			}, 15)
	// 				// 	}
	// 				// })
	// 				// worksheet["!cols"] = maxWidthArr

	// 				let maxWidthArr = Object.keys(rows1[0]).map((item) => {
	// 					return {
	// 						wch:
	// 							rows1
	// 								.map((r) => r[item])
	// 								.reduce((acc, curr) => {
	// 									return Math.max(acc, curr.toString().length)
	// 								}, 15)
	// 					}
	// 				})
	// 				// console.log("maxWidthArr = ", maxWidthArr)
	// 				worksheet["!cols"] = maxWidthArr
	// 				// console.log("rows = ", rows)

	// 				let fmt = "#,##0.00 [$UZS]"
	// 				let range = { s: { r: 1, c: 6 }, e: { r: rows.length - 1, c: 6 } }
	// 				for (let R = range.s.r; R <= range.e.r; ++R) {
	// 					for (let C = range.s.c; C <= range.e.c; ++C) {
	// 						let cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
	// 						if (!cell || cell.t !== "n") continue
	// 						cell.z = fmt
	// 					}
	// 				}

	// 				// XLSX.utils.sheet_add_aoa(worksheet, [["Test"]], {
	// 				// 	origin: "F23"
	// 				// })

	// 				let workbook = XLSX.utils.book_new()
	// 				XLSX.utils.book_append_sheet(workbook, worksheet, t("payment.title"))
	// 				// worksheet.F23 = { t: "s", v: "Test" }
	// 				XLSX.writeFile(
	// 					workbook,
	// 					`Chiqim.xlsx`,
	// 					{
	// 						type: "array",
	// 						bookType: "xlsx"
	// 					}
	// 				)
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.error(err)
	// 		})
	// 		.finally(() => setDataLoading2(false))
	// 	}
	const handleGetAllData = ()=>{
		setDataLoading2(true)
		let entries = Object.fromEntries(searchParams)
		let values = { from: entries?.from ? entries?.from : moment().startOf("month").format("YYYY-MM-DD"), till: entries.till ? entries?.till : moment().endOf("month").format("YYYY-MM-DD"), object_id: entries?.object, block_id: entries?.block,reason_id: entries?.reason_id, payment_type: entries?.payment_type }
		axiosPrivate
			.get("/report", {
				headers: { "Content-Type": "application/json" },
				params: values,
				responseType: "blob"

			})
			// t(
			// 	paymentTypeSelectOptions.find(
			// 		(paymentType) => paymentType.code === item?.type_id
			// 	)?.label
			// )

			.then((res) => {
				console.log(res);
				saveAs(res?.data, "Chiqim-kirim.xlsx")
			}).catch((err) => {
				console.error(err)
			})
			.finally(() => setDataLoading2(false))
	}
	const handleGetData = () => {
		setDataLoading(true)
		let entries = Object.fromEntries(searchParams)
		let values = { from: entries?.from, till: entries?.till, object_id: entries?.object, block_id: entries?.block, reason_id: entries?.reason_id, payment_type: entries?.payment_type }
		axiosPrivate
			.post("/utilities/data/exportExpenses", JSON.stringify(values), {
				headers: { "Content-Type": "application/json" }
			})
			// t(
			// 	paymentTypeSelectOptions.find(
			// 		(paymentType) => paymentType.code === item?.type_id
			// 	)?.label
			// )
			.then((res) => {
				// /utilities/data/exportExpenses
				console.log(res);
				if (res && res.data) {
					let rows1 = res.data?.map((item) => ({
						"To'lov raqami": item?.id,
						"Shartnoma raqami": item?.object_name === null? "-": item?.object_name,
						"Mas'ul": item?.who_made,
						Sana: moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						"To'lov turi": item?.payment_type,
						// t(
						// 	paymentTypeSelectOptions.find(
						// 		(paymentType) => paymentType.code === item?.type_id
						// 	)?.label
						// ),
						"To'lov maqsadi": item?.reason_name,
						"To'lov sababi": item?.comments,
						Summa: +item?.sum.split(",").join("").split(".")[0],
						Tranzaksiya: "Kiruvchi"
					}))
					let rows = res.data?.map((item) => [
						item?.id,
						item?.object_name === null? "-": item?.object_name,
						item?.who_made,
						moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						// "",
						item?.payment_type,
						// t(
						// 	paymentTypeSelectOptions.find(
						// 		(paymentType) => paymentType.code === item?.type_id
						// 	)?.label
						// ),
						item?.reason_name,
						item?.comments,
						+item?.sum.split(",").join("").split(".")[0],
						// "",
						"Chiquvchi"
					])
					rows.unshift([
						"Chiqim raqami",
						"Obyekt Nomi",
						"Chiqim qilgan hodim",
						"Sana",
						"Chiqim turi",
						"Chiqim maqsadi",
						"Chiqim sababi",
						"Summa",
						"Tranzaksiya"
					])
					let worksheet = XLSX.utils.aoa_to_sheet(rows)
					// let worksheet = XLSX.utils.json_to_sheet(rows1)
					// let maxWidthArr = Object.keys(rows[0]).map((item) => {
					// 	return {
					// 		wch: rows
					// 			.map((r) => r[item])
					// 			.reduce((acc, curr) => {
					// 				return Math.max(acc, curr.toString().length)
					// 			}, 15)
					// 	}
					// })
					// worksheet["!cols"] = maxWidthArr
					let maxWidthArr = Object.keys(rows1[0]).map((item) => {
						return {
							wch:
								rows1
									.map((r) => r[item])
									.reduce((acc, curr) => {
										return Math.max(acc, curr.toString().length)
									}, 15)
						}
					})
					// console.log("maxWidthArr = ", maxWidthArr)
					worksheet["!cols"] = maxWidthArr
					// console.log("rows = ", rows)

					// let fmt = "###0.00 [$UZS]"
					// let range = { s: { r: 1, c: 6 }, e: { r: rows.length - 1, c: 6 } }
					// console.log(range);
					// for (let R = range.s.r; R <= range.e.r; ++R) {
					// 	for (let C = range.s.c; C <= range.e.c; ++C) {
					// 		let cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
					// 		if (!cell || cell.t !== "n") continue
					// 		cell.z = fmt
					// 	}
					// }

					// XLSX.utils.sheet_add_aoa(worksheet, [["Test"]], {
					// 	origin: "F23"
					// })

					let workbook = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(workbook, worksheet, t("payment.title"))
					// worksheet.F23 = { t: "s", v: "Test" }
					XLSX.writeFile(
						workbook,
						`Chiqim.xlsx`,
						{
							type: "array",
							bookType: "xlsx"
						}
					)
				}
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => setDataLoading(false))
		// let data = [
		// 	{ Nomi: "Moran", Roli: "back" },
		// 	{ Nomi: "Alain", Roli: "front" },
		// 	{ Nomi: "Tony", Roli: "back" },
		// 	{ Nomi: "Mike", Roli: "back" },
		// 	{ Nomi: "Abo", Roli: "back" },
		// 	{ Nomi: "Toni", Roli: "back" }
		// ]

		// const ws = XLSX.utils.json_to_sheet(data)

		/* const ws = XLSX.utils.sheet_add_aoa(
			[],
			[
				["", "Nomi", "Roli"],
				["", "xx1", "yy1"],
				["", "xx2", "yy2"],
				["", "xx3", "yy3"],
				["", "xx4", "yy4"],
				["", "xx5", "yy5"],
				["", "xx6", "yy6"],
				["", "xx7", "yy7"]
			],
			{
				skipHeader: true,
				origin: -1
			}
		) */

		// ws["A1"].s = { fill: { fgColor: { rgb: "#111111" } } }
		// const wb = XLSX.utils.book_new()
		// XLSX.utils.book_append_sheet(wb, ws, t("payment.title"))

		// XLSX.writeFile(wb, `${t("payment.title")}.xlsx`)
	}
	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-1 flex flex-row items-center">
					<div className="header-actions filter-box flex justify-between items-center my-shadow-2 rounded-lg px-4 w-full h-14">
						<Button
								variant="filterOutlined"
								color="primary"
								startIcon={<i className="bi bi-filter" />}
								className="!mr-2"
								onClick={() => setExpanded((prev) => !prev)}
							>
								{t("common.button.filter")}
						</Button>
						<div className="header-actions filter-box flex items-center rounded-lg h-14">
						<div className="ml-2 flex pointer-events-none invisible w-0">
							<FromDateToDateFilter />
						</div>
						<Button
							variant="outlined"
							color="success"
							className="!mr-2 flex hover:bg-slate-700"
							onClick={() => handleGetAllData()}
							disabled={dataLoading2}
						>
							{dataLoading2 ? (
								<div className="flex items-center justify-center">
									HISOBOT
									<CircularProgress size={17.5} className="ml-2" color="inherit" />
								</div>
							) : (
								<span className="flex">
									HISOBOT
									<i className="bi bi-download ml-2" />
								</span>
							)}
						</Button>
						</div>
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
							className="!mr-2"
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						{process.env.REACT_APP_API_URL.includes("api.laylomchj.uz") ? 
							user?.user?.role === ROLE_TYPE_LIST.ADMIN.code && (
								<Button
									variant="action"
									color="success"
									className="!mr-2"
									onClick={() => setOpenExpenseModal(true)}
								>
									<i className="bi bi-plus-circle" />
								</Button>
								)
						:(
								<Button
									variant="action"
									color="success"
									className="!mr-2"
									onClick={() => setOpenExpenseModal(true)}
								>
									<i className="bi bi-plus-circle" />
								</Button>
						)}
						<Button
							variant="action"
							color="primary"
							className="!mr-2"
							onClick={() => handleGetData()}
							disabled={dataLoading}
						>
							{dataLoading ? (
								<div className="flex items-center justify-center">
									<CircularProgress size={17.5} color="inherit" />
								</div>
							) : (
								<i className="bi bi-download" />
							)}
						</Button>
						{/* <Button
							variant="action"
							color="primary"
							className="!mr-2"
							onClick={() => handleGetDataIncome()}
							disabled={dataLoading2}
						>
							{dataLoading2 ? (
								<div className="flex items-center justify-center">
									<CircularProgress size={17.5} color="inherit" />
								</div>
							) : (
								<i className="bi bi-upload" />
							)}
						</Button> */}
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
				{expanded && (
					<div className="my-shadow-2 rounded-lg px-4 w-full mt-2">
						<ExpenseFilterComponent />
					</div>
				)}
			</div>
			{/* <div className="mb-5">
				<Grid
					container
					spacing={2}
					columns={{ xs: 14, sm: 14, md: 14, lg: 14, xl: 14 }}
				>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-green-700 flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("payment.totalReceipts")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="inherit"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={12}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]" 
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color flex flex-col pl-2">
								<span className="text-[13px] text-gray-600">
									{t("Umumiy chiqim")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="inherit"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={12}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium text-[14px]" 
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					</Grid>
					</div> */}
			<div className="component-table-wrapper">
				<ExpenseTable
					emitRefetch={{ refetch, setRefetch }}
					dataPath="accounter/rasxod/index"
					filterSort
					headCells={[
						{ code: "id", label: t("common.table.expenseNumber") },
						{ code: "sum", label: t("common.table.expenseSum") },
						{
							code: "payment_type",
							label: t("common.table.expensePaymentType")
						},
						{ code: "date", label: t("common.table.date") },
						{ code: "object_name", label: t("common.table.objectName") },
						{ code: "block_name", label: t("common.table.blockName") },
						{ code: "comment", label: t("common.table.expenseReason") },
						{ code: "reason", label: t("common.table.expenseComment") }
					]}
					columns={[
						{ code: "id" },
						{ code: "sum", type: "price" },
						{
							code: "payment_type",
							type: "customStatus",
							variants: paymentTypeVariants
						},
						{ code: "date", type: "date" },
						{ code: "object" },
						{ code: "block" },
						{ code: "comment" },
						{ code: "reason" }
					]}
					emitTableColumns={{ open, setOpen }}
					tableName="expense"
					actionDelete={
						{
							icon: "bi bi-eye",
							btnColor: "info",
							link: "accounter/rasxod/destroy",
							key: "id"
						}
					}
				/>
			</div>
			{openExpenseModal && (
				<ExpenseAddModal
					open={openExpenseModal}
					setOpen={setOpenExpenseModal}
					setRefetch={setRefetch}
				/>
			)}
		</div>
	)
}

export default Expense
