import useTopPanel from "hooks/useTopPanel"
import { Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import FormActionButtons from "components/ui/form/FormActionButtons"
import FormPasswordField from "components/ui/form/FormPasswordField"
import FormTextField from "components/ui/form/FormTextField"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNotification from "hooks/useNotification"
import { useFormik } from "formik"
import * as yup from "yup"
import { useTranslation } from 'react-i18next'
import useLocalStorage from "hooks/useLocalStorage"
const Profile = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("profile.title")}
			</div>
		)
	}, [i18n.language])

	return (
		<Grid
			container
			spacing={{ xs: 2, sm: 3, lg: 2 }}
			rowSpacing={3}
			columns={{ xs: 10, sm: 10, lg: 10 }}
			marginTop={"5px"}


			>
			<Fragment>

				<Grid xs={10} sm={10} lg={4} s
				marginLeft={"15px"}
				>

				<div  className="rounded-[7px] px-4 py-9 bg-[#fff]"  style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
				<Grid item={true} sm={10} xs={10}
				container
				columns={{ xs: 10, sm: 10 }}
			>

<Grid item={true}  sm={5} xs={10} className='w-full flex justify-center items-start'>
	<img src={require("assets/images/logo/playmobile.png")} alt="" />
</Grid>
<Grid item={true} sm={5} xs={10}>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
	<Grid sm={10} xs={10}>12312</Grid>
</Grid>	

			</Grid>
			</div>	
				</Grid>
			
			</Fragment>
			
		</Grid>
	   
	)
}

export default Profile
