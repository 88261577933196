import { Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import ShablonContract from './contractShablonTabs/ShablonContract';

const ContractShablons = () => {

    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event,newValue) => {
        setTabValue(newValue);
    };
    console.log(tabValue);
  return (
    <div className="bg-[#fff] p-2 rounded-[7px]" style={{boxShadow:"0 0 #0000, 0 0 #0000, 0 0 5px 1px #bb89d585"}}>
        <div>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                TabIndicato
                >
                <Tab  label="Shartnoma" />
                <Tab  label="Chek [tulovlar]" />
                <Tab label="Act" />
            </Tabs>
            {
                tabValue === 0 &&(
                <ShablonContract />
                )
            }
        </div>
    </div>
  )
}

export default ContractShablons