import { Fragment, useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableRow from "@mui/material/TableRow"
import { Button, CircularProgress, LinearProgress, TableHead } from "@mui/material"
import { useQuery } from "react-query"
import setTableCellType from "utils/setTableCellType"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import GenerateCheque from "../action-buttons/GenerateCheque"
import DeleteActionButton from "../action-buttons/DeleteActionButton"
import moment from "moment"
import { paymentTypeVariants } from "shared/tableColVariantsList"
import { useTranslation } from "react-i18next"
import generateChequeFromData from "utils/generateCheque"
import useNotification from "hooks/useNotification"
import {NumberToText} from "hooks/NumberToText"

export default function PaymentHistoryTable({ dataPath = "", title = "",actionDelete, arrayGenerateToWord, generateToWord }) {
	const { t } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [hasError, setHasError] = useState(false)
	const [tableRows, setTableRows] = useState([])

	const columns = [
		{ code: "payment_number" },
		{ code: "date", type: "date" },
		{ code: "sum", type: "priceCurrency" },
		{
			code: "type_id",
			type: "customStatus",
			variants: paymentTypeVariants
		},
		{ code: "staff", type: "nested", childStr: "name" }
	]
	const headCells = [
		{ code: "payment_number", label: t("common.table.paymentId") },
		{ code: "date", label: t("common.table.date") },
		{ code: "sum", label: t("common.table.sum") },
		{ code: "type_id", label: t("common.table.typeId") },
		{ code: "staff", label: t("common.table.staff") }
	]

	const { error, isLoading, isFetching, isError, refetch } = useQuery({
		queryKey: dataPath + "Key",
		queryFn: async function () {
			const response = await axiosPrivate.get(`/${dataPath}`)
			return response.data
		},
		onSuccess: (result) => {
			if (result && result.data && result.data.length > 0) {
				result.data.sort((x, y) => moment(y.date) - moment(x.date))
				setTableRows([...result.data])
			} else {
				setTableRows([])
			}
		},
		enabled: !hasError,
		onError: (error) => {
			setHasError(true)
		},
		retry: false
	})
	const generateDatasWord = (dataWord)=>{
		// let allSum = 0
		// let dollarCurr = 0
		// const filterRows = tableRows.filter(item => item.date === dataWord.date && item.status == 1  &&(
		// 	item.isvalute == 1 ? dollarCurr += (+item.sum * +item.valute) :
		// 	allSum += +item.sum
		// ))
		// arrayGenerateToWord({
		// 	id: filterRows[0].id,
		// 	sum: +allSum + +dollarCurr,
		// 	payment: {
		// 		typeOne: filterRows.filter(item => item.types.id == 1).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeTwo: filterRows.filter(item => item.types.id == 2).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeThree: filterRows.filter(item => item.types.id == 3).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeFour: filterRows.filter(item => item.types.id == 4).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeFive: filterRows.filter(item => item.types.id == 5).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeSix: filterRows.filter(item => item.types.id == 6).reduce((acc, curr) => {return +acc + +curr.sum} ,0),
		// 		typeSeven: filterRows.filter(item => item.types.id == 7).reduce((acc, curr) => {return +acc + +curr.sum} ,0)
		// 	},
		// 	sumText: NumberToText(+allSum + +dollarCurr),
		// 	date: filterRows[0].date,
		// 	valute: filterRows.map(item=> item.isvalute == 1 && item.valute),
		// 	dataLenght: filterRows.length,
		// 	buttonactive: true
		// })
		
	}
	
	return (
		<Fragment>
			{title && (
				<div className="payment-history-title my-2 text-lg font-medium text-base-color">
					<span className="mr-2">{title}</span>
					{/* {generateToWord.buttonactive &&	
					(<><Button 
						color="success"
						type="button"
						variant="outlined"
						onClick={() =>{
							generateChequeFromData({
								paymentId: generateToWord.id,
								someCheck: generateToWord,
								arrayGenerateToWord: arrayGenerateToWord,
								IsSubmittingFn: setIsSubmitting,
								axiosPrivate: axiosPrivate,
								sendNotification: sendNotification
							})
						}
						}
					>
					Chekni birlashtirish 
					{isSubmitting ? (
					<CircularProgress className="ml-1" size={15} color="inherit" />
					) : (
					""
					)}
					</Button>
					<span className="ml-2">{generateToWord.date} sanasida {generateToWord.dataLenght} ta chek mavjud</span>
					</>)} */}
					
				</div>
			)}
			<Box className="base-table w-full h-full flex flex-col">
				<TableContainer className="flex-auto h-[500px]">
					<Table
						stickyHeader
						sx={{ minWidth: 750, height: "max-content" }}
						aria-labelledby="tableTitle"
					>
						<TableHead>
							<TableRow>
								<TableCell key="head-cell-index-number">№</TableCell>
								{headCells.map((headCell) => (
									<TableCell key={"head-cell-" + headCell.code}>
										<div dangerouslySetInnerHTML={{ __html: headCell.label }} />
									</TableCell>
								))}
								<TableCell>{t("common.table.actions")}</TableCell>
							</TableRow>
						</TableHead>
						{isLoading || isFetching ? (
							<TableBody className="overflow-hidden">
								<TableRow>
									<TableCell colSpan={headCells.length + 2}>
										<LinearProgress />
									</TableCell>
								</TableRow>
							</TableBody>
						) : isError ? (
							<TableBody className="overflow-hidden">
								<TableRow>
									<TableCell colSpan={headCells.length + 2}>
										<div className="flex flex-col items-center">
											{error?.response?.data?.message && (
												<span className="text-red-600 font-medium">
													{error?.response?.data?.message}
												</span>
											)}
											{error?.response?.data?.details &&
												error?.response?.data?.details[0]?.message && (
													<div>
														<span className="text-red-600 font-medium">
															{t("common.errors.message")}
														</span>
														<span>
															{error?.response?.data?.details[0]?.message}
														</span>
													</div>
												)}
										</div>
									</TableCell>
								</TableRow>
							</TableBody>
						) : tableRows && tableRows.length > 0 ? (
							<Fragment>
								<TableBody className="overflow-hidden">
									{tableRows.map((row, rowIndex) => (
										<TableRow hover tabIndex={-1} key={"row-" + rowIndex}  className={`${generateToWord && generateToWord.buttonactive && generateToWord.date === row.date ? "!bg-[#5c1b7f13] duration-300" : row.status === "0" ? "deleted": ""}`}>
											<TableCell>{rowIndex + 1}</TableCell>
											{columns.map((column, columnIndex) => (
												<TableCell
													align={
														headCells[columnIndex].numeric ? "right" : "left"
													}
													style={{cursor: "pointer"}}
													onClick={()=>{
														generateDatasWord(row)
													}}
													key={"column-" + columnIndex}
												>
													{column.type
														? setTableCellType(column, row[column.code], row)
														: row[column.code]}
												</TableCell>
											))}
											<TableCell className="flex justify-end" style={{
												display: "flex"
											}}>
												{row.status === "1" &&
												<GenerateCheque id={row.id} />
												}
												
												
												{actionDelete && (
													<DeleteActionButton
														link={actionDelete.link+'/'+row.id}
														data={{ [actionDelete.key]: row.id }}
														refetch={refetch}
														methodDelete={actionDelete.methodDelete}
														buttonStyle={row.status === "1"? false : true}
													/>
												)}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Fragment>
						) : (
							<TableBody>
								<TableRow>
									<TableCell colSpan={headCells.length + 2}>
										<span className="no-data-found-wrapper">
											<i className="bi bi-exclamation-octagon icon-xl" />{" "}
											{t("common.global.noDataFound")}
										</span>
									</TableCell>
								</TableRow>
							</TableBody>
						)}
					</Table>
				</TableContainer>
			</Box>
		</Fragment>
	)
}
