import { Step, StepLabel, Stepper } from "@mui/material"
import { useEffect, useState } from "react"
import StepThree from "./steps/StepThree"
import StepFour from "./steps/StepFour"
import PhoneFormat from "components/ui/text-formats/PhoneFormat"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import useTopPanel from "hooks/useTopPanel"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { fromBase64ToTextParser } from "utils/textParser"
import useNotification from "hooks/useNotification"
import StepOneFromShaxmatka from "./steps/StepOneFromShaxmatka"

const ContractAddFromShaxmatka = () => {
	const { t, i18n } = useTranslation()
	const steps = [
		t("contract.add.step.home"),
		t("contract.add.step.custom"),
		t("contract.add.step.payment"),
		t("contract.add.step.contract")
	]
	const [activeStep, setActiveStep] = useState(1)
	const [completed, setCompleted] = useState({})
	const [clientData, setClientData] = useState(null)
	const [homeData, setHomeData] = useState(null)
	const [homeDataTemplate, setHomeDataTemplate] = useState([
		"id",
		"number",
		"stage",
		"rooms",
		"square",
		"repaired",
		"norepaired",
		"isvalute",
	])
	const [paymentData, setPaymentData] = useState(null)
	const [slideDirection, setSlideDirection] = useState("left")
	const { setComponent } = useTopPanel()
	const [searchParams, setSearchParams] = useSearchParams()
	const sendNotification = useNotification()

	useEffect(() => {
		setComponent(
			<div className="flex flex-row items-center">
				<div className="component-title">{t("contract.add.title")}</div>
			</div>
		)
	}, [i18n.language])

	const totalSteps = () => {
		return steps.length
	}

	const completedSteps = () => {
		return Object.keys(completed).length
	}

	const isLastStep = () => {
		return activeStep === totalSteps() - 1
	}

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps()
	}

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1
		setActiveStep(newActiveStep)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const setHomeDataFromQueryParams = () => {
		let entries = Object.fromEntries(searchParams)
		if (entries && entries.homeData) {
			let hData = JSON.parse(fromBase64ToTextParser(entries.homeData))
            
			let validHomeD = Object.keys(hData).filter(
				(item) =>
					item && homeDataTemplate.includes(item) && Boolean(hData[item])
			)
			if (validHomeD.length === homeDataTemplate.length) {
				setHomeData(hData)
			} else {
				sendNotification({
					msg: "Uy ma'lumotlari to'liq emas",
					variant: "warning"
				})
			}
		}
	}

	useEffect(() => {
		setHomeDataFromQueryParams()
	}, [])

	return (
		<div className="component-add-edit-wrapper h-calc(100%-0.25rem)">
			<div className="component-add-edit-body mt-3 h-full">
				<div className="flex flex-col h-full">
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label, index) => (
							<Step key={label} completed={completed[index]}>
								<StepLabel StepIconComponent={StepIcons} className="text-lg">
									<span className="text-base">{label}</span>
								</StepLabel>
							</Step>
						))}
					</Stepper>
					<div className="selected-items-wrapper my-shadow-2 border-dashed border-base-color border-[2px] mx-36 mt-6 p-2 rounded-lg flex min-h-[100px]">
						{homeData && (
							<div className="selected-item w-1/3 bg-base-color-light text-white p-2 rounded-lg flex flex-col justify-center mr-2">
								<div className="name text-lg">{homeData?.blocks?.name}</div>
								<div className="home-details text-base flex justify-between mt-2">
									<span>
										{t("contract.add.details", {
											stage: homeData.stage,
											rooms: homeData.rooms
										})}
									</span>
									<span>№{homeData.number}</span>
								</div>
							</div>
						)}

						{clientData && (
							<div className="selected-item w-1/3 bg-base-color-light text-white p-2 rounded-lg flex flex-col justify-center mx-2">
								<div className="name text-lg">
									{clientData?.surname} {clientData?.name}{" "}
									{clientData?.middlename}
								</div>
								<div className="phone text-base">
									<PhoneFormat value={clientData.phone} />
								</div>
							</div>
						)}

						{paymentData && (
							<div className="selected-item w-1/3 bg-base-color-light text-white p-2 rounded-lg flex flex-col justify-center ml-2">
								<div className="sum text-lg">
									<CurrencyFormat
										value={paymentData.sum}
										suffix={paymentData.isvalute === "1" ? " $" : " UZS"}
									/>
								</div>
								<div className="start-price text-base">
									<CurrencyFormat
										value={paymentData.start_price}
										suffix={paymentData.isvalute === "1" ? " $" : " UZS"}
									/>
								</div>
								<div className="month text-base text-end">
									{t("contract.add.month", { value: paymentData.month })}
								</div>
							</div>
						)}
					</div>

					<div className="stepper-items-wrapper px-5 mt-4 pb-2 flex-auto overflow-y-auto overflow-x-hidden">
						<div className="stepper-item">
							<StepOneFromShaxmatka
								direction={slideDirection}
								appear={activeStep === 1}
								next={handleNext}
								setData={setClientData}
								homeData={homeData}
							/>
						</div>
						<div className="stepper-item">
							<StepThree
								direction={slideDirection}
								appear={activeStep === 2}
								next={handleNext}
								back={handleBack}
								homeData={homeData}
								setData={setPaymentData}
							/>
						</div>
						<div className="stepper-item">
							<StepFour
								direction={slideDirection}
								appear={activeStep === 3}
								back={handleBack}
								clientData={clientData}
								homeData={homeData}
								paymentData={paymentData}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

function StepIcons(props) {
	const { active, completed } = props

	const icons = {
		1: (
			<div className="step-icon">
				<i className="bi bi-building-add" />
			</div>
		),
		2: (
			<div className="step-icon">
				<i className="bi bi-person-add" />
			</div>
		),
		3: (
			<div className="step-icon">
				<i className="bi bi-cash" />
			</div>
		),
		4: (
			<div className="step-icon">
				<i className="bi bi-file-earmark-text" />
			</div>
		)
	}

	return (
		<div
			className={`step-icon-wrapper${completed ? " competed" : ""}${
				active ? " active" : ""
			}`}
		>
			{icons[String(props.icon)]}
		</div>
	)
}

export default ContractAddFromShaxmatka
