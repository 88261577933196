import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"

const BaseTableHead = (props) => {
	const { headCells, filterSort, sortByPayment, setOrdered, ordered , setOrderBy, orderBy} = props
	const { t } = useTranslation()
	const [order, setOrder] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const handleChange = (e)=>{
		if(sortByPayment){
			if(orderBy == "payment_number"){
			ordered.date = false

			setOrdered(prev => (
				{...prev,
					[e]: !prev[e]
				}
			))
			
		}

		else{
			ordered.paymentNumber = false
			setOrdered(prev => (
				{...prev,
					[e]: !prev[e]
				}
			))
			
		}
		}
		else{
		setOrder(prev => !prev)
		searchParams.set("sort_by", order?"asc":"desc")
		setSearchParams(searchParams)
		}
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, headCellIndex) => (
					<Fragment key={"head-cell-" + headCell.code}>
						<TableCell
							align={headCell?.numeric ? "right" : "left"}
							padding={headCell?.disablePadding ? "none" : "normal"}
							display={"flex"}
						>
							<div dangerouslySetInnerHTML={{ __html: headCell.label }} style={{display: "inline-block"}} />
							{filterSort && headCellIndex == 0 &&
								<div
								className="!absolute right-0 top-1/2 -translate-y-1/2  cursor-pointer"
								name="paymentNumber"
								onClick={(e)=> {
									handleChange(e?.target?.attributes?.name?.value)
									sortByPayment && setOrderBy("payment_number")
								}}
								>
									<TableSortLabel 
										className="pointer-events-none"	
										active={headCellIndex == 0 && true}
										direction={sortByPayment ? ordered.paymentNumber ? "desc" : "asc" : order? 'desc' : 'asc'}
									>

									</TableSortLabel>
								</div>
							}
			{sortByPayment && headCellIndex == 3 &&
							<div
							className="!absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer"
							name="date"
							onClick={(e)=> {
								handleChange(e?.target?.attributes?.name?.value)
								
								sortByPayment && setOrderBy("date")
							}}
							>
							<TableSortLabel 
							className="pointer-events-none"
							active={headCellIndex == 3 && true}
							direction={sortByPayment ? ordered.date ? "desc" : "asc" : order? 'desc' : 'asc'}
							>
							</TableSortLabel>
							</div>
							}
						</TableCell>
					</Fragment>
				))}
				<TableCell padding="normal" align="right">
					{t("common.table.actions")}
				</TableCell>
			</TableRow>
		</TableHead>
	)
}

export default BaseTableHead
